@import 'erpcore/assets/scss/main';

.multiple-choice-repeater-item__columns {
    margin-bottom: 0 !important;

    .form__grid-column {
        display: flex;
        align-items: center;

        .input-switch {
            margin-bottom: 0;
        }
    }
}
