@import 'erpcore/assets/scss/main';

.main {
    :global {
        .widget__content {
            display: block;
            overflow: hidden;
            align-items: flex-start;
        }
    }
}

.inner {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    height: 100%;
}
