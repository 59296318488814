@import 'erpcore/assets/scss/main';

.DateRangePickerInput {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    border-radius: 3px;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    background-color: rgba(getColor(white), 1);
    border: 2px solid rgba(getColor('light-grey'), 1);
    padding: 0 ($spacing * 6) 0 ($spacing * 2);

    &_arrow {
        opacity: 0;
        visibility: hidden;
        margin-right: $spacing;
        transition: getTransition();

        &_svg {
            width: $spacing * 2;
            height: $spacing * 2;
        }
    }
}

.DateInput {
    width: 95px;
    display: block;
    background-color: transparent;

    &_input {
        padding: 0;
        width: 100%;
        border: none;
        display: block;
        font: $font-form;
        box-shadow: none;
        border-radius: 0;
        height: $spacing * 4.5;
        color: rgba(getColor(black), 1);
        -webkit-appearance: none;

        &__focused {
            border-bottom: none;
        }
    }
}

.DayPicker {
    @include breakpoint($to: tablet) {
        padding-top: 30px;
    }
}

.DayPicker_focusRegion {
    padding-bottom: $spacing * 2;
}

.DateRangePicker {
    &_picker {
        z-index: 500;
    }

    .input__action {
        top: 2px;
    }
}

.DateRangePickerInput__disabled {
    background-color: getColor(light-grey);
}

.CalendarMonth_table {
    margin-top: 20px;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
    background-color: getColor(red);
    border: 1px solid getColor(red);
}

.CalendarDay__selected_span {
    background-color: lighten(getColor(red), 25%);
    border: 1px solid lighten(getColor(red), 15%);
}

.CalendarDay__default:hover {
    color: getColor(white);
    background-color: getColor(red-hover);
    border: 1px solid getColor(red-hover);
}

.CalendarDay__hovered_span {
    color: getColor(white);
    background-color: lighten(getColor(red), 25%);
    border: 1px solid lighten(getColor(red), 15%);
}

.input--daterange .input__action {
    top: 2px;
}

.input--daterange {
    .daterange-presets {
        position: absolute;
        z-index: 2;
        top: $spacing * 2 + 2px;
        transform: translateX(-50%);
        left: 50%;

        @include breakpoint($to: tablet) {
            top: 12px;
        }

        &__select {
            max-width: 145px;
            border: none;
            padding: $spacing / 2 0;
            background: rgba(getColor(light-grey), 0.5);

            @include breakpoint($to: tablet) {
                max-width: 100%;
                font-size: 16px;
            }
        }
    }
}

.input--error .DateRangePickerInput {
    border: 2px solid getColor(tomato);
}

.input--active .DateRangePickerInput_arrow {
    opacity: 1;
    visibility: visible;
}
