@import 'erpcore/assets/scss/main';

.event-view-info {
    padding: $spacing * 4;
    background-color: getColor('light-grey');
    display: flex;
    justify-content: space-between;

    @include breakpoint($to: tablet) {
        flex-wrap: wrap;
        padding: $spacing * 2;
    }

    &__item {
        flex-basis: fit-content;

        &:last-child {
            margin-right: 0;
        }

        p {
            margin-bottom: $spacing / 2;
            font: $font-label;
            color: getColor('mid-grey');
        }

        h3 {
            font-weight: 300;
            margin-bottom: 0;
        }

        @include breakpoint($to: tablet) {
            flex-basis: 100%;
            margin-bottom: $spacing * 2;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
