@import 'erpcore/assets/scss/main';

.event-view-testimonials {
    padding: $spacing * 3;
    background-color: getColor('light-grey');
    width: 100%;
    max-height: 500px;
    overflow: auto;

    &-single {
        display: flex;
        justify-content: space-between;
        margin-bottom: $spacing * 3;
        border-bottom: 1px solid getColor('silver-grey');
        padding-bottom: $spacing * 3;

        @include breakpoint(mobile) {
            flex-direction: column;
        }

        &:last-child {
            border-bottom: unset;
            padding-bottom: 0;
            margin-bottom: 0;
        }

        &__participant {
            flex-basis: 250px;
            max-width: 250px;
            min-width: 250px;
            margin-right: $spacing * 5;
            display: flex;
            align-items: flex-start;

            @include breakpoint(tablet) {
                flex-basis: 200px;
                max-width: 200px;
                min-width: 250px;
                margin-right: $spacing * 5;
            }

            @include breakpoint(mobile) {
                flex-basis: 100%;
                max-width: 100%;
                min-width: 100%;
                margin-right: 0;
                margin-bottom: $spacing * 2;
            }

            &-image {
                background-size: cover;
                background-position: center center;
                width: 40px;
                min-width: 40px;
                height: 40px;
                border-radius: 50%;
                margin-right: $spacing * 2;

                &--default {
                    width: 40px;
                    min-width: 40px;
                    height: 40px;
                    background-color: getColor('silver-grey');
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: $spacing * 2;

                    svg {
                        fill: white;
                    }
                }
            }

            p {
                position: relative;
                top: 11px;
            }
        }

        &__content {
            flex-grow: 1;

            p {
                margin-top: $spacing * 2;
            }
        }
    }
}
