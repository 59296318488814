@import 'erpcore/assets/scss/main';

.status-badge {
    $root: &;
    text-overflow: ellipsis;
    height: 17px;
    white-space: nowrap;
    position: relative;
    font: $font-caption;
    text-transform: uppercase;
    display: inline-block;
    padding: 2px 6px;
    border-radius: 3px;
    background: getColor(sky);
    color: getColor(white);

    &--sky {
        background: getColor(sky);
        color: getColor(white);
    }

    &--tomato {
        background: getColor(tomato);
        color: getColor(white);
    }

    &--neon {
        background: getColor(neon);
        color: getColor(white);
    }

    &--sun {
        background: getColor(sun);
        color: getColor(white);
    }

    &--orange {
        background: getColor(orange);
        color: getColor(white);
    }

    &--apple {
        background: getColor(apple);
        color: getColor(white);
    }

    &--lavander {
        background: getColor(lavander);
        color: getColor(white);
    }

    &--grey {
        background: getColor(silver-grey);
        color: getColor(white);
    }

    .list & {
        flex-shrink: 0;
        margin: $spacing $spacing * 2 0 0;
    }
}
