@import 'erpcore/assets/scss/main';

.leadUser {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
}

.leadUserSvg {
    top: -1px;
    position: relative;
    margin-right: $spacing;

    svg path {
        fill: getColor(sun);
    }
}
