@import 'erpcore/assets/scss/main';

.switch-group {
    display: block;
    border-radius: 3px;
    margin-bottom: $spacing * 2;
    border: 2px solid getColor(light-grey);

    &__main {
        border: none;
    }

    &__password {
        width: 100%;
        padding: $spacing $spacing * 2 $spacing * 2 $spacing * 2;
    }

    &__password-field {
        padding-top: $spacing;
    }

    &__chat {
        padding: $spacing * 2;
        position: relative;

        ul {
            margin: $spacing 0 $spacing;
        }

        &-preview {
            position: absolute !important;
            bottom: $spacing * 3;
            right: $spacing * 2;
            width: $spacing * 3;
        }
    }
}
