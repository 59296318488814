@import 'erpcore/assets/scss/main';

.challenges-listing {
    tr td {
        &:nth-child(2) {
            min-width: 250px;
            max-width: 550px;

            @include breakpoint(desktop) {
                max-width: 400px;
            }

            @include breakpoint($from: tablet) {
                min-width: 200px;
            }
        }

        &:nth-child(4){
            width: 100%;
        }
    }

    &__name {
        display: flex;

        &-inner-html {
            img {
                max-width: 100px !important;
                max-height: 100px !important;
                object-fit: cover;
            }
        }

        &-image {
            width: 45px;
            flex-basis: 45px;
            min-width: 45px;
            height: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: getColor('light-grey');
            margin-right: $spacing * 2;
            overflow: hidden;

            img {
                width: 100%;
                height: auto;
            }

            &--placeholder {
                img {
                    width: 60%;
                    height: auto;
                }
            }
        }

        &-content {
            h4 {
                margin-bottom: $spacing;
            }

            p {
                font-weight: 300 !important;
            }
        }
    }
}
