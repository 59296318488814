@import 'erpcore/assets/scss/main';

.wrapper {
    display: flex;
    flex-wrap: wrap;

    :global([data-is-image="0"]) & {
        margin-right: 12px;
    }

    &__icon {
        display: block;
        width: 26px;
        height: 26px;
        fill: #fff;
    }

    &__tooltip {
        display: flex;
        flex-wrap: wrap;
        max-width: 300px;
        margin-bottom: -4px;

        &-item {
            display: flex;
            flex-wrap: nowrap;
            margin: 0 $spacing 4px 0;

            &:last-child {
                margin-right: 0;
            }

            &-icon {
                display: block;
                width: 16px;
                height: 16px;
                margin-top: 1px;
                fill: getColor(mid-grey);
            }

            &-text {
                font: $font-label;
                color: #fff;
            }
        }
    }
}
