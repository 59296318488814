@import 'erpcore/assets/scss/main';

.main {
    width: 100%;
    display: flex;
    flex: 1 1 auto;
    overflow: hidden;
    flex-wrap: nowrap;
    flex-direction: column;

    :global {
        .tabs {
            flex: 0 0 auto;
            padding-bottom: $spacing * 2;
            border-bottom: 1px solid getColor(silver-light-grey);
        }

        .table__body-row td {
            &:nth-child(2) {
                width: 140px;
            }

            &:nth-child(3) {
                width: 90px;
            }

            &:nth-child(4) {
                width: 130px;
            }

            &:last-child {
                width: 100px;
                white-space: nowrap;
            }
        }

        .table__body-data--right {
            padding-right: $spacing * 2;
        }
    }
}

.listing {
    display: block;
    margin-bottom: $spacing * 3;

    &:last-child {
        margin-bottom: $spacing;
    }

    &__title {
        font: $font-4;
        display: block;
        margin-bottom: $spacing;
    }

    &__inner {
        flex: 1 1 auto;
        overflow-y: auto;
        overflow-x: hidden;
        padding-top: $spacing * 3;
    }

    :global {
        .table {
            border-top: none;
            padding-bottom: 0;
            border-bottom: none;

            &__header {
                display: none;
            }
        }

        .table-mobile__list-item {
            @include breakpoint(tablet) {
                padding: $spacing * 2;
            }
        }
    }
}

.name {
    display: flex;
    flex-wrap: nowrap;

    &__pic {
        display: block;
        width: $spacing * 4;
        height: $spacing * 4;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
}
