@import 'erpcore/assets/scss/main';

.image-editor {
    position: relative;
    display: grid;
    width: 100%;
    grid-template-columns: 0.72fr 0.28fr;
    grid-template-rows: auto max-content min-content;
    grid-template-areas:
        "canvas canvas"
        "controls meta"
        "actions meta";
    grid-column-gap: $spacing*4;
    grid-row-gap:  $spacing*2;
    min-height: 64px;

    @include breakpoint($to: tablet) {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-template-areas:
            "canvas"
            "controls"
            "actions"
            "meta";
    }

    &__canvas {
        position: relative;
        grid-area: canvas;

        &-cropper {
            display: block;
            width: 100%;
            height: 374px;
            background: getColor(athens-grey);
            box-shadow: inset 0px 0px 0px 1px getColor(light-grey);
            overflow: hidden;

            .cropper-crop-box-natural-dimensions {
                display: block;
                position: absolute;
                top: -13px;
                left: 0;
                font-size: 9px;
                line-height: 1;
                font-weight: 400;
                letter-spacing: 0.5px;
                color: #fff;
                white-space: nowrap;
                padding: 2px 4px;
                background: rgba(#39f, 0.7);
                transition: getTransition();
                pointer-events: none;

                &[data-position=top-out] {
                    top: -13px;
                }

                &[data-position=top-in] {
                    top: 0px;
                }
            }
        }

        &-loader {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
        }
    }

    &__meta {
        grid-area: meta;

        &-item {
            margin: 0 0 $spacing/2;
            font: $font-list;
            color: getColor(mid-anthracite);

            &-title {
                font-weight: 700;
            }
        }

        .react-select__menu {
            // top: auto;
            // bottom: calc(100% + #{$spacing});
        }
    }

    &__editable-meta {
        margin: $spacing 0 $spacing * 2;
        max-width: 377px;
    }

    &__controls {
        grid-area: controls;
        margin: 0 0 $spacing;

        .button {
            margin: 0 $spacing $spacing 0;
        }

        &-button {
            padding: 12px;

            &:last-child {
                margin-right: 0;
            }

            .button__icon {
                width: $spacing*2;
                height: $spacing*2;

                svg {
                    width: $spacing*2;
                    height: $spacing*2;
                    fill: getColor(dark-anthracite);
                }
            }

            &--large-icon {
                padding: $spacing;

                .button__icon {
                    width: $spacing*3;
                    height: $spacing*3;

                    svg {
                        width: $spacing*3;
                        height: $spacing*3;
                    }
                }
            }

            &:hover,
            &:active,
            &:focus {
                .button__icon svg {
                    fill: getColor(dark-anthracite);
                }
            }

            &.button--disabled {
                .button__icon {
                    svg {
                        fill: getColor(white);
                    }
                }
            }
        }
    }

    &__actions {
        grid-area: actions;
    }

    &__loader {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        background: rgba(getColor(white), 0.75);
        z-index: 1;
    }
}
