@import 'erpcore/assets/scss/main';

.event-view-leaderboards {
    padding: 0 $spacing * 4;
    background-color: getColor('light-grey');
    margin-bottom: $spacing * 3;

    &__achievements {
        display: flex;

        &-image {
            margin-right: $spacing * 2;
        }

        &-content {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
        }

        &-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: $spacing;

            & > span {
                font-size: 12px;
                line-height: 12px;
                color: getColor('mid-grey');
            }
        }

        &-team {
            display: flex;

            h4 {
                margin-right: $spacing;
                margin-bottom: 0;
            }
        }

        &-status {
            display: flex;

            & > span {
                color: getColor('mid-grey');
                margin-right: $spacing * 2;
            }
        }

        &-line {
            flex-grow: 1;
            position: relative;

            div {
                & > div {
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }
    }
}
