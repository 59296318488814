@import 'erpcore/assets/scss/main';

.activities-listing {
    &__name {
        display: flex;

        &-image {
            width: 45px;
            flex-basis: 45px;
            min-width: 45px;
            height: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: getColor('light-grey');
            margin-right: $spacing * 2;
            overflow: hidden;

            img {
                width: 100%;
                height: auto;
            }

            &--placeholder {
                img {
                    width: 60%;
                    height: auto;
                }
            }
        }

        &-content {
            h4 {
                margin-bottom: $spacing;
                white-space: normal;
            }

            p {
                font-weight: 300 !important;
            }
        }
    }
}
