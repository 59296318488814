@import 'erpcore/assets/scss/main';

.entity-challenges {
    &__icon {
        display: inline-flex;
        vertical-align: text-bottom;
        position: relative;

        .svg {
            width: 27px;
        }
    }

    &__right {
        display: flex;
        align-items: center;

        .form,
        .input-switch {
            margin-bottom: 0;
        }

        .form {
            width: fit-content;
            margin-right: $spacing;
        }

        & > span {
            display: inline-flex;
            margin-right: $spacing * 3;
        }
    }

    &__switch-wrapper {
        position: relative;
    }

    &__dot {
        width: $spacing;
        height: $spacing;
        border-radius: 100%;
        display: inline-block;
        margin-right: $spacing;
        background-color: getColor(silver-light-grey);
    }

    &__dot--active {
        background-color: getColor(orange);
    }

    .table-mobile__table-col-left{
        padding: 0 0 16px;
    }

    .table-mobile__table-col-right{
        padding: 0;
    }
}
