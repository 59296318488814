@import 'erpcore/assets/scss/main';

.listing-picture {
    width: auto;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: flex-start;
    align-items: flex-start;

    &__image {
        width: 45px;
        flex-basis: 45px;
        min-width: 45px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: getColor('light-grey');
        margin-right: $spacing * 2;
        overflow: hidden;
        font-weight: bold;

        @include fileTypesBackgroundWithText;

        img {
            width: 100%;
            height: auto;
        }

        &--placeholder {
            img {
                width: 60%;
                height: auto;
            }
        }
    }

    &__content {
        h4 {
            margin-bottom: 0;
            white-space: normal;
            padding-bottom: $spacing / 2;
        }

        p {
            display: block;
            max-width: 240px;
            white-space: normal;
            word-break: break-all;
        }
    }
}
