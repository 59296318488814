@import 'erpcore/assets/scss/main';

.challenge-settings {
    &__wall-tooltip {
        width: 100%;
    }

    &__switch-card {
        margin-bottom: $spacing * 2;
        border: 2px solid getColor('light-grey');
        padding-bottom: $spacing * 2;

        .input {
            max-width: 96%;
            margin: 0 auto;
        }

        &--disabled {
            .input {
                border: 2px solid rgba(getColor('mid-grey'), 0.2);
            }
        }
    }

    &__switch {
        border: 0;
        margin-bottom: $spacing;
    }

    &__switch-locks{
        margin-bottom: 16px;
    }
}
