@import 'erpcore/assets/scss/main';

.ReactModal {
    &__Overlay {
        z-index: 10000;
        overflow: auto;
        color: getColor(black);
        background-color: rgba(black, 0.4) !important;
    }
    &__Body {
        &--open {
            overflow: hidden;
        }
    }
}

.modal {
    $this: &;
    width: 100%;
    min-height: 100%;
    display: flex;
    overflow: auto;
    flex-wrap: nowrap;
    align-items: center;
    padding: $spacing * 3;
    align-content: center;
    justify-content: center;
    pointer-events: none;

    @include breakpoint($to: tablet) {
        padding: 0;
    }

    &__inner {
        z-index: 2;
        width: 100%;
        pointer-events: all;
        max-width: $spacing * 100;
        text-align: left;
        position: relative;
        padding: 20px $spacing * 3 $spacing * 3 $spacing * 3;
        background-color: getColor(white);
        max-height: 100%;
        border-radius: 3px;
        box-shadow: 0 0 38px 0 rgba(25, 25, 35, 0.38);

        @include breakpoint(tablet) {
            width: 100%;
            max-width: 100%;
            border-radius: 0;
            min-height: 100vh;
        }

        &--small {
            @include breakpoint($from: tablet) {
                max-width: $spacing * 44;
            }
        }

        &--medium {
            @include breakpoint($from: tablet) {
                max-width: $spacing * 60;
            }
        }

        &--large {
            @include breakpoint($from: tablet) {
                max-width: $spacing * 120;
            }
        }

        &--transparent {
            background-color: transparent;
            width: fit-content;
            padding: 0;
            box-shadow: none;
            position: initial;

            @include breakpoint(tablet) {
                min-height: fit-content;
            }

            #{$this}__close {
                @include breakpoint(tablet) {
                    top: $spacing * 9;
                }
            }

            #{$this}__header {
                margin: 0;
            }
        }

        &--media {
            @include breakpoint($from: tablet) {
                max-width: $spacing * 134;
            }
        }
    }

    &__header {
        margin-bottom: $spacing * 3;
    }

    &__title {
        font: $font-1;
        word-wrap: break-word;
        margin-bottom: 0px;
        padding-right: $spacing * 3;
    }

    &__subtitle {
        font: $font-body;
        margin-top: $spacing;
        word-break: normal;
        overflow-wrap: break-word;
    }

    &__close {
        margin: 0;
        padding: 0;
        z-index: 10;
        border: none;
        cursor: pointer;
        top: 28px;
        position: absolute;
        right: $spacing * 3;
        -webkit-appearance: none;
        background-color: transparent;

        svg {
            fill: getColor(black);
            transition: getTransition();
        }

        &:hover {
            svg {
                fill: getColor(red);
            }
        }
    }

    &__content {
        position: relative;
        word-break: normal;
        overflow-wrap: break-word;
    }
}
