@import 'erpcore/assets/scss/main';

.tooltip {
    display: inline-block;

    &-content {
        height: 0px;
        line-height: 0px;
    }
}

.react-tooltip-lite {
    background: getColor(black);
    color: getColor(white);
    font: $font-body;
    border-radius: 3px;
    box-shadow: 0px 3px 6px 0 rgba(10, 9, 12, 0.19);
    margin-top: 3px;
}

.react-tooltip-lite-arrow {
    margin-top: 3px;
    border-color: getColor(black);
}
