@import 'erpcore/assets/scss/main';

.user-listing-name {
    display: flex;
    align-items: center;
    $_image_size: $spacing * 5;
    $_svg_margin: 4px;
    $_svg_size: $_image_size - ($_svg_margin * 2) - ($_svg_margin / 2);

    &__image {
        flex: 0 0 ($spacing * 5);
        display: flex;
        align-items: center;
        justify-content: center;
        width: $_image_size;
        height: $_image_size;
        margin-right: $spacing;
        border: 1px solid getColor(silver-light-grey);
        border-radius: 50%;
        background-color: getColor(silver-light-grey);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        transition: getTransition();
        overflow: hidden;

        img {
            display: block;
            width: 100%;
            height: auto;
            overflow: hidden;
        }

        &-svg {
            display: block;
            width: $_svg_size;
            height: $_svg_size;
            margin: $_svg_margin;
            fill: getColor(white);
            border-radius: 50%;
            overflow: hidden;

            svg {
                width: $_svg_size;
                height: $_svg_size;
            }
        }
    }

    &__details {
        flex: 1 1 auto;
    }

    &__title {
        display: block;
        width: 100%;
        margin: 0;
        font-weight: 700;
    }

    &__subtitle {
        display: block;
        width: 100%;
        margin: 0;
        font-weight: 400;
    }
}
