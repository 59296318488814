@import 'erpcore/assets/scss/main';

.event-view-edit-points {
    .input {
        width: 100px;
        max-width: 100px;

        input {
            height: 25px;
        }
    }
}
