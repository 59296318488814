@import 'erpcore/assets/scss/main';

.tag-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.tag {
    display: block;
    width: auto;
    max-width: 100%;
    flex: 0 0 auto;
    margin: 0 6px 6px 0;
    padding: 0 $spacing;
    border-radius: $spacing;
    height: 22px;
    font: $font-label;
    line-height: 22px;
    color: getColor(main-grey);
    background: getColor(light-grey);
}
