@import 'erpcore/assets/scss/main';

.entity__notifications {
    &-table-column-message {
        word-break: break-all;
    }

    .table__body-row {
        td {
            max-width: 500px;
        }
    }
}
