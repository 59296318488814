@import 'erpcore/assets/scss/main';

.block {
    display: block;

    p {
        margin: 0;
        padding-bottom: $spacing;
    }

    &__inner {
        padding-bottom: $spacing;
    }
}
