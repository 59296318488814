@import 'erpcore/assets/scss/main';

.entity-challenge-add-new {
    &__existing-challenges {
        border: 2px solid getColor('light-grey');
        padding: $spacing * 1.5;
        margin-bottom: $spacing * 2;
    }

    &__info {
        font: $font-4;
        font-weight: 300;
    }

    &__search {
        padding-left: $spacing;
        width: 100%;
        border: none;
        display: block;
        font-size: 14px;
        border-radius: 3px;
        height: $spacing * 4;
        -webkit-appearance: none;
        font-family: $font_family;
        color: getColor(dark-grey);
        border-bottom: 1px solid getColor('light-grey');
        margin-bottom: $spacing * 2;
    }

    &__wrapper {
        height: 37vh;
        overflow: auto;
        margin-top: $spacing;
        margin-bottom: $spacing;
    }

    &__card {
        display: flex;
        padding: $spacing * 1.5 0;
        cursor: pointer;
        background-color: transparent;
        transition: getTransition();

        &:hover {
            background-color: rgba(getColor('dark-grey'), 0.1);
        }

        &-image {
            width: 90px;
            flex-basis: 90px;
            min-width: 90px;
            height: 90px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: getColor('light-grey');
            margin-right: $spacing * 2;
            overflow: hidden;

            img {
                width: 100%;
                height: auto;
            }

            &--placeholder {
                img {
                    width: 30%;
                    height: auto;
                }
            }
        }

        &-content {
            h4 {
                margin-bottom: $spacing;
            }

            p {
                margin-bottom: $spacing;

                &:last-of-type {
                    margin-bottom: 0;
                }

                img {
                    max-width: 100px;
                }
            }
        }
    }
}
