@import 'erpcore/assets/scss/main';

.event-view-details {
    padding: $spacing * 4;
    background-color: getColor('light-grey');
    display: flex;
    margin-bottom: $spacing * 3;

    &__show-more {
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: getColor('light-grey') !important;
        padding: 0 $spacing * 1.8;
    }

    &__image {
        width: 160px;
        height: 160px;
        min-width: 160px;
        background-color: rgba(getColor('mid-grey'), 0.2);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: $spacing * 2;

        @include breakpoint($to: desktop) {
            width: 110px;
            height: 110px;
            min-width: 110px;
        }

        &--placeholder {
            img {
                width: 50% !important;
            }
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    &__content {
        flex-grow: 1;
        height: 160px;
        overflow: hidden;
        position: relative;

        @include breakpoint($to: desktop) {
            height: 110px;
        }

        p {
            font: $font-body;
        }

        &--expanded {
            overflow: visible;
            height: auto;
        }

        & > p:first-child {
            margin-bottom: $spacing;
            font-size: 11px;
            line-height: 11px;
        }

        &-description {
            white-space: pre-wrap;
        }
    }
}
