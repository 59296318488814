@import 'erpcore/assets/scss/main';

.horizontal-line-chart {
    display: flex;
    flex-direction: column;
    margin-bottom: $spacing * 1.5;
    &:last-of-type {
        margin-bottom: 0;
    }

    &__title {
        margin-bottom: $spacing * 1.5;
    }

    &__singleBar {
        width: 100%;
        margin-bottom: $spacing / 3;
        transition: width 0.7s ease;

        &-tooltip {
            width: 100%;
            height: 100%;
            span {
                width: 0;
                transition: width 0.7s ease;
                border-radius: 5px;
                height: $spacing;
                min-height: $spacing;
                display: inline-block;
                width: 100%;
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}
