@import 'erpcore/assets/scss/main';

.event-view-coordinators {
    padding: $spacing * 4;
    background-color: getColor('light-grey');
    height: 100%;
    margin-bottom: $spacing * 3;

    &__coordinator {
        margin-bottom: $spacing;
        display: flex;

        &:last-child {
            margin-bottom: 0;
        }

        &-image {
            margin-right: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            min-width: 40px;
            height: 40px;
            overflow: hidden;
            background-color: rgba(getColor('mid-grey'), 0.2);
            border-radius: 50%;
            user-select: none;
            touch-action: manipulation;
            white-space: nowrap;
        }

        &-content {
            margin-bottom: $spacing * 2;

            &:last-child {
                margin-bottom: 0;
            }

            p {
                margin-bottom: 0;
                font-size: 12px;
                line-height: 12px;
                color: rgba(getColor('mid-grey'), 0.7);
                white-space: normal;
                word-break: break-all;
            }

            h4 {
                margin-bottom: $spacing;
                font: $font-3;
                font-weight: 300;
            }
        }
    }
}
