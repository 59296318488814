@import 'erpcore/assets/scss/main';

.page-content {
    $this: &;
    position: relative;
    width: 100%;
    display: block;
    padding: 0 0 ($spacing * 3) 0;

    &__section {
        position: relative;
        display: block;
        width: 100%;
        margin-bottom: $spacing * 4;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__column {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        width: 100%;
        border: none;
        padding: 0;
        margin: 0;
    }

    &__columns {
        display: flex;
        justify-content: space-between;
        margin-bottom: $spacing * 2;

        &:last-child {
            margin-bottom: 0;
        }

        @include breakpoint($to: tablet) {
            flex-direction: column;
        }

        #{$this}__column {
            flex-basis: 50%;
            width: 50%;
            margin-right: $spacing * 2;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__grid-column {
        width: 50%;
        flex: 0 0 50%;
        display: flex;
        flex-direction: column;

        &--1 {
            flex-basis: calc(8.333333% - #{$spacing * 7} + (#{$spacing * 7} / 12));
            max-width: calc(8.333333% - #{$spacing * 7} + (#{$spacing * 7} / 12));
        }

        &--2 {
            flex-basis: calc(16.666667% - #{$spacing * 7} + (#{$spacing * 7} / 6));
            max-width: calc(16.666667% - #{$spacing * 7} + (#{$spacing * 7} / 6));
        }

        &--3 {
            flex-basis: calc(25% - #{$spacing * 7} + (#{$spacing * 7} / 4));
            max-width: calc(25% - #{$spacing * 7} + (#{$spacing * 7} / 4));
        }

        &--4 {
            flex-basis: calc(33.333333% - #{$spacing * 7} + (#{$spacing * 7} / 3));
            max-width: calc(33.333333% - #{$spacing * 7} + (#{$spacing * 7} / 3));
        }

        &--5 {
            flex-basis: 41.666667%;
            max-width: 41.666667%;
        }

        &--6 {
            flex-basis: calc(50% - #{$spacing * 7} + (#{$spacing * 7} / 2));
            max-width: calc(50% - #{$spacing * 7} + (#{$spacing * 7} / 2));
        }

        &--7 {
            flex-basis: 58.333333%;
            max-width: 58.333333%;
        }

        &--8 {
            flex-basis: 66.666667%;
            max-width: 66.666667%;
        }

        &--9 {
            flex-basis: 75%;
            max-width: 75%;
        }

        &--10 {
            flex-basis: 83.333333%;
            max-width: 83.333333%;
        }

        &--11 {
            flex-basis: 91.666667%;
            max-width: 91.666667%;
        }

        &--12 {
            flex-basis: 100%;
            max-width: 100%;
            margin-right: 0;
        }

        @include breakpoint($to: tablet) {
            width: 100%;
            max-width: 100%;
            flex: 0 0 100%;
            margin-right: 0;
            margin-bottom: $spacing * 2;
        }
    }

    &__row {
        display: flex;
        flex-wrap: wrap;
    }

    &__title {
        display: inline-block;
        width: 100%;
        font: $font-2;
        margin: ($spacing * 2) 0px ($spacing * 3) 0px;

        &:first-child {
            margin-top: 0px;
        }
    }

    &--dashboard {
        @include breakpoint($to: mobile) {
            .page-content__columns {
                flex-direction: column;
            }

            .page-content__column {
                width: 100%;
                flex-basis: 100%;
                margin-right: 0;
                margin-bottom: $spacing * 2;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &__helper-list {
        margin-bottom: $spacing * 3;
    }
}
