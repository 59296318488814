@import 'erpcore/assets/scss/main';

.form-wrapper{
    display: flex;
    flex-direction: column;

    & > div {
        width: 100%;
        margin-bottom: $spacing*3;

        &:last-child{
            margin-bottom: 0;
        }
    }
}

.form-wrapper__inline{
    margin: 0 auto $spacing*3;
}