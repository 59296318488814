@import 'erpcore/assets/scss/main';

.event-summary-mail {
    &:first-child {
        margin-top: $spacing * 2;
    }
}

.switch-group {
    display: block;
    border-radius: 3px;
    margin-bottom: $spacing * 2;
    border: 2px solid getColor(light-grey);

    &__main {
        border: none;
    }

    &__password {
        width: 100%;
        padding: $spacing $spacing * 2 $spacing * 2 $spacing * 2;
    }

    &__password-field {
        padding-top: $spacing;
    }

    &__chat {
        padding: $spacing * 2;
        position: relative;

        ul {
            margin: $spacing 0 $spacing
        }

        &-preview {
            position: absolute !important;
            bottom: $spacing*3;
            right: $spacing*2;
            width: $spacing*3;
        }
    }
}

.welcome-email {
    $this: &;
    padding: $spacing * 2;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 100%;
    text-align: left;
    border-radius: 3px;
    border: 2px solid getColor('light-grey');
    margin-bottom: $spacing * 2;
}