@import 'erpcore/assets/scss/main';

.event-feed-card {
    padding: $spacing * 3;
    margin: 0 0 $spacing * 3;
    border: 2px solid getColor('light-grey');
    border-radius: 4px;
    display: flex;
    position: relative;

    @include breakpoint(mobile) {
        flex-direction: column;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &__info {
        border-right: 1px solid rgba(getColor('mid-grey'), 0.4);
        flex-basis: 30%;
        max-width: 30%;
        min-width: 30%;

        @include breakpoint(mobile) {
            border-right: 0;
            max-width: 100%;
            flex-basis: 100%;
            min-width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        @include breakpoint(small) {
            flex-direction: column;
            align-items: flex-start;
        }

        &-time {
            display: flex;
            align-items: center;
            color: getColor('mid-grey');
            font-size: 12px;
            line-height: 12px;
            margin-bottom: $spacing * 3;

            @include breakpoint(mobile) {
                align-items: flex-start;
            }

            .svg {
                width: 13px;
                height: auto;
                margin-right: $spacing;
                fill: getColor('mid-grey');
            }
        }

        &-item {
            display: flex;
            align-items: flex-start;
            margin-bottom: $spacing * 3;
            font: $font-body;

            .svg {
                width: 20px;
                height: auto;
                margin-right: $spacing * 1.5;
                fill: getColor('mid-grey');
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        &-points {
            p {
                margin-bottom: 0;

                &:nth-child(2) {
                    color: getColor('mid-grey');
                    position: relative;
                    width: fit-content;

                    &:before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                        height: 0.5px;
                        width: 100%;
                        background-color: getColor('mid-grey');
                    }
                }
            }
        }

        &-edit {
            margin-left: $spacing * 1.5;
            border: 0;
            background-color: transparent;
            padding: 0;
            margin-right: 0;

            .svg {
                fill: getColor(black);
                width: 16px;
                margin-right: 0;
            }
        }
    }

    &__content {
        flex-grow: 1;
        margin-left: $spacing * 5;

        @include breakpoint(mobile) {
            margin-left: 0;
        }

        &-answer {
            margin-bottom: $spacing * 2 !important;

            video {
                width: 100%;
                max-width: 250px;
                height: auto;
                background-color: #000;
            }
        }

        &-badge {
            width: 20px;
            height: 20px;
            display: inline-flex;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            margin-right: $spacing;

            &--correct {
                background-color: getColor(apple);
            }

            &--incorrect {
                background-color: getColor(tomato);
            }

            .svg {
                width: 10px;
                fill: white !important;
            }
        }

        &-title {
            font-weight: 300;

            .svg {
                margin-right: $spacing;
                fill: getColor('mid-grey');
            }
        }

        &-description {
            color: getColor('mid-grey');
            margin-bottom: $spacing * 2;
        }

        &-status {
            display: flex;
            justify-content: flex-end;
            margin-bottom: $spacing * 2;

            @include breakpoint(small){
                margin-top: $spacing*2;
                justify-content: flex-start;
            }
        }

        &-command {
            .svg,
            svg {
                width: 13px;
                margin-right: $spacing;
                fill: getColor('mid-grey') !important;
            }

            &--locked {
                color: getColor('mid-grey');
            }
        }
    }
}
