@import 'erpcore/assets/scss/main';

.tabs {
    $this: &;
    width: 100%;
    font: $font-4;
    display: block;
    color: getColor(black);
    padding: 0 0 ($spacing * 4) 0;

    &__list {
        margin: 0;
        padding: 0;
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        list-style-type: none;
        align-items: flex-start;
        align-content: flex-start;
        justify-content: flex-start;
        overflow-x: auto;
        overflow-y: hidden;

        &-item {
            padding: 0;
        }
    }

    &__link {
        cursor: pointer;
        display: block;
        width: 100%;
        text-decoration: none;
        color: getColor(mid-grey);
        padding: 0 ($spacing * 2);
        white-space: nowrap;
        border: none;
        border-radius: 0;
        -webkit-appearance: none;
        background-color: transparent;
        font: $font-4;

        #{$this}__list-item:first-child & {
            padding-left: 0;
        }

        &:hover {
            text-decoration: none;
            color: getColor(dark-grey-hover);
        }

        &:focus {
            outline: 1px solid transparent;
        }

        &--active {
            cursor: default;
            color: getColor(light-blue);

            &:hover {
                color: getColor(light-blue);
            }
        }
    }
}
