@import 'erpcore/assets/scss/main';

.challenge-form {
    .gallery__item {
        min-width: 120px;
    }

    &__type {
        position: relative;
        margin-bottom: $spacing * 2;
    }

    .text-editor{
        z-index: 3
    }
}
