@import 'erpcore/assets/scss/main';

.comments {
    position: relative;
    margin: 0 0 ($spacing * 3) 0;
    padding: 0px;

    .notification {
        margin: 0px 0px ($spacing * 3) 0px;
    }

    .element-loader {
        position: absolute;
        top: 10%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10;
    }

    &__content {
        position: relative;
        max-height: 36vh;
        margin: 0px 0px ($spacing * 3) 0px;
        padding: 0px ($spacing * 2) 0px 0px;
        overflow: hidden;
        overflow-y: auto;

        @include breakpoint($to: tablet) {
            max-height: none;
            padding: 0px;
        }
    }

    &__comment {
        position: relative;
        list-style-type: none;
        border-bottom: 1px solid $border-color;
        padding: ($spacing) ($spacing * 4) ($spacing) 0px;

        &:first-child {
            border-top: 1px solid $border-color;
        }

        &-user {
            font: $font-5;
            margin-bottom: $spacing;

            &-icon {
                display: inline-block;
                width: 12px;
                margin-right: ($spacing/2);

                svg {
                    display: block;
                    width: 100%;
                    height: auto;
                    fill: getColor(mid-grey);
                }
            }
        }

        &-date {
            font: $font-label;
            color: getColor(mid-grey);
            margin-left: $spacing;
        }

        &-content {
            font: $font-list;
        }

        &-delete {
            position: absolute;
            bottom: $spacing;
            right: 0px;
            z-index: 1;
            display: flex;
            cursor: pointer;
            border-radius: 0;
            flex-wrap: nowrap;
            text-align: center;
            align-items: center;
            width: 14px;
            align-content: center;
            text-decoration: none;
            justify-content: center;
            border: none;
            background-color: transparent;
            padding: 0;
            -webkit-appearance: none;
            transition: getTransition();

            svg {
                fill: getColor(black);
                transition: getTransition();
            }

            &:hover {
                svg {
                    fill: getColor(red);
                }
            }
        }
    }

    &__view-all {
        font: $font-list;
        color: getColor(red);
        border: none;
        background: transparent;
        cursor: pointer;
        transition: getTransition();
        margin: 0px;
        padding: 0px;
        -webkit-appearance: none;

        &:hover {
            color: getColor(red-hover);
            text-decoration: none;
        }

        &:active {
            color: getColor(red-hover);
            text-decoration: none;
        }
    }
}
