@mixin fileTypesBackground {
    &[data-file-type='pdf'] {
        background-color: #c03630;
    }

    &[data-file-type='zip'],
    &[data-file-type='zipx'],
    &[data-file-type='rar'],
    &[data-file-type='tar'],
    &[data-file-type='7z'] {
        background: #d78e0f;
    }

    &[data-file-type='csv'],
    &[data-file-type='xls'],
    &[data-file-type='xlsx'],
    &[data-file-type='xlsm'],
    &[data-file-type='xlsb'],
    &[data-file-type='ods'] {
        background: #217346;
    }

    &[data-file-type='ppt'],
    &[data-file-type='pptx'],
    &[data-file-type='pptm'] {
        background: #b7472a;
    }

    &[data-file-type='doc'],
    &[data-file-type='docx'],
    &[data-file-type='docm'],
    &[data-file-type='odt'] {
        background: #2b579a;
    }
}

@mixin fileTypesBackgroundWithText {
    &[data-file-type='pdf'] {
        color: white;
        background-color: #c03630;
    }

    &[data-file-type='zip'],
    &[data-file-type='zipx'],
    &[data-file-type='rar'],
    &[data-file-type='tar'],
    &[data-file-type='7z'] {
        color: white;
        background: #d78e0f;
    }

    &[data-file-type='csv'],
    &[data-file-type='xls'],
    &[data-file-type='xlsx'],
    &[data-file-type='xlsm'],
    &[data-file-type='xlsb'],
    &[data-file-type='ods'] {
        color: white;
        background: #217346;
    }

    &[data-file-type='ppt'],
    &[data-file-type='pptx'],
    &[data-file-type='pptm'] {
        color: white;
        background: #b7472a;
    }

    &[data-file-type='doc'],
    &[data-file-type='docx'],
    &[data-file-type='docm'],
    &[data-file-type='odt'] {
        color: white;
        background: #2b579a;
    }
}
