@import 'erpcore/assets/scss/main';
$margin: $spacing * 3;
$columns: 12;

.widget {
    $this: &;
    position: relative;
    flex: 1;
    width: 100%;
    max-width: 100%;
    min-height: 1px;
    margin: 0 0 $margin 0;
    background: getColor(white);
    border-radius: 3px;
    padding: $spacing * 2;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 3px 0 rgba(102, 103, 112, 0.42);

    .listing {
        height: 100%;
    }

    &:last-child {
        margin-right: 0;
    }

    @include breakpoint(tablet) {
        flex: 1 1 100%;
    }

    @include breakpoint($from: tablet) {
        padding: $spacing * 3;
        margin: 0 $margin $margin 0;
    }

    &__draggable {
        &-icon {
            display: none;
            position: absolute;
            top: $spacing;
            left: 6px;
            z-index: 1;
            opacity: 0.5;
            cursor: pointer;

            #{$this}:hover & {
                display: block;
            }

            @include breakpoint(tablet) {
                top: 4px;
                left: 2px;
            }
        }
    }

    &__header {
        display: flex;
        flex: 0 0 auto;
        flex-wrap: nowrap;
        justify-content: space-between;
        margin-bottom: $spacing * 2;

        &:last-child {
            margin-bottom: 0;
        }

        &-main {
            flex: 1;
            flex-basis: 100%;
        }

        &-aside {
            flex: 1;
            text-align: right;
            position: relative;
            padding-left: $spacing * 2;
            white-space: nowrap;
        }

        &--no-margin {
            margin-bottom: 0;
        }
    }

    &__title {
        position: relative;
        font: $font-2;
        margin: 0;
    }

    &__subtitle {
        font: $font-list;
        color: getColor(silver-grey);
        margin: 0;
    }

    &__filters {
        display: flex;
        padding-top: 4px;

        .input {
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;
            align-items: center;

            &__label {
                background-color: transparent;
                position: relative;
                margin-right: $spacing;
                height: 100%;
                display: flex;
                align-items: center;
                padding: 0;
                font: $font-body;
                color: getColor(black);
            }

            &--active {
                .input__label {
                    transform: translate(0px, 0px);
                }
            }
        }

        .react-select {
            &__value-container {
                display: flex;
                height: 100%;
                align-items: center;
            }

            &__control {
                height: 40px;
                display: block;
                font: $font-body;
                padding: 0 $spacing 0 0;
                border-radius: 3px;
                position: relative;
                color: #3d3e42;
                background-color: #ffffff;
                border: none;
            }

            &__single-value {
                display: block;
                padding: 0px $spacing * 4 0 0;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                color: getColor(red);
                font: $font-body;
            }

            &__dropdown-indicator {
                fill: getColor(red);
            }
        }
    }

    &__content {
        position: relative;
        display: flex;
        flex-grow: 1;
        margin: 0;
        align-items: center;
        outline: none;
        padding: 8px 0 0 0;

        @include breakpoint(tablet) {
            display: block;
        }

        & > * {
            @include breakpoint(tablet) {
                margin-bottom: $spacing * 2;
            }

            &:last-child {
                @include breakpoint(tablet) {
                    margin-bottom: 0;
                }
            }
        }

        .listing {
            margin-bottom: 0;
        }

        .table-mobile__list {
            margin-left: -2 * $spacing;
            margin-right: -2 * $spacing;
        }

        &-row {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            align-items: flex-start;
            align-content: flex-start;
            margin-left: -1 * ($spacing * 3 / 2);
            margin-right: -1 * ($spacing * 3 / 2);
            width: calc(100% + #{2 * ($spacing * 3 / 2)});
        }

        &-col {
            padding-top: 2 * $spacing;
            padding-left: $spacing * 3 / 2;
            padding-right: $spacing * 3 / 2;

            @for $i from 1 through $columns {
                &--size-#{$i} {
                    max-width: percentage(1 / $columns * $i);
                    flex-basis: percentage(1 / $columns * $i);
                }
            }

            @include breakpoint(mobile) {
                width: 100%;
                max-width: 100%;
                flex-basis: 100%;
            }
        }
    }

    // region Columns

    &--1 {
        flex-basis: calc(8.333333% - #{$margin} + (#{$margin} / 12));
        max-width: calc(8.333333% - #{$margin} + (#{$margin} / 12));

        &:nth-of-type(12n) {
            margin-right: 0;
        }
    }

    &--2 {
        flex-basis: calc(16.666667% - #{$margin} + (#{$margin} / 6));
        max-width: calc(16.666667% - #{$margin} + (#{$margin} / 6));

        &:nth-of-type(6n) {
            margin-right: 0;
        }

        @include breakpoint(desktop) {
            flex-basis: calc(50% - #{$margin} + (#{$margin} / 4));
            max-width: calc(50% - #{$margin} + (#{$margin} / 4));

            &:nth-of-type(2n) {
                margin-right: 0;
            }
        }

        @include breakpoint(mobile) {
            flex-basis: 100%;
            max-width: 100%;
            margin-right: 0;
        }
    }

    &--3 {
        flex-basis: calc(25% - #{$margin} + (#{$margin} / 4));
        max-width: calc(25% - #{$margin} + (#{$margin} / 4));

        &:nth-of-type(4n) {
            margin-right: 0;
        }

        @include breakpoint(desktop) {
            flex-basis: calc(50% - #{$margin} + (#{$margin} / 4));
            max-width: calc(50% - #{$margin} + (#{$margin} / 4));

            &:nth-of-type(2n) {
                margin-right: 0;
            }
        }

        @include breakpoint(mobile) {
            flex-basis: 100%;
            max-width: 100%;
            margin-right: 0;
        }
    }

    &--4 {
        flex-basis: calc(33.333333% - #{$margin} + (#{$margin} / 3));
        max-width: calc(33.333333% - #{$margin} + (#{$margin} / 3));

        &:nth-of-type(3n) {
            margin-right: 0;
        }

        @include breakpoint(tablet) {
            flex-basis: 100%;
            max-width: 100%;
            margin-right: 0;
        }
    }

    &--5 {
        flex-basis: calc(41.666667% - #{$margin} + (#{$margin} / 2));
        max-width: calc(41.666667% - #{$margin} + (#{$margin} / 2));

        @include breakpoint(tablet) {
            flex-basis: 100%;
            max-width: 100%;
            margin-right: 0;
        }
    }

    &--6 {
        flex-basis: calc(50% - #{$margin} + (#{$margin} / 2));
        max-width: calc(50% - #{$margin} + (#{$margin} / 2));

        &:nth-of-type(2n) {
            margin-right: 0;
        }

        @include breakpoint(tablet) {
            flex-basis: 100%;
            max-width: 100%;
            margin-right: 0;
        }
    }

    &--7 {
        flex-basis: calc(58.333333% - #{$margin} + (#{$margin} / 2));
        max-width: calc(58.333333% - #{$margin} + (#{$margin} / 2));

        @include breakpoint(tablet) {
            flex-basis: 100%;
            max-width: 100%;
            margin-right: 0;
        }
    }

    &--8 {
        flex-basis: calc(66.666667% - #{$margin} + (#{$margin} / 2));
        max-width: calc(66.666667% - #{$margin} + (#{$margin} / 2));

        @include breakpoint(tablet) {
            flex-basis: 100%;
            max-width: 100%;
            margin-right: 0;
        }
    }

    &--9 {
        flex-basis: calc(75% - #{$margin} + (#{$margin} / 2));
        max-width: calc(75% - #{$margin} + (#{$margin} / 2));

        @include breakpoint(tablet) {
            flex-basis: 100%;
            max-width: 100%;
            margin-right: 0;
        }
    }

    &--10 {
        flex-basis: calc(83.333333% - #{$margin} + (#{$margin} / 2));
        max-width: calc(83.333333% - #{$margin} + (#{$margin} / 2));

        @include breakpoint(tablet) {
            flex-basis: 100%;
            max-width: 100%;
            margin-right: 0;
        }
    }

    &--11 {
        flex-basis: calc(91.666667% - #{$margin} + (#{$margin} / 2));
        max-width: calc(91.666667% - #{$margin} + (#{$margin} / 2));

        @include breakpoint(tablet) {
            flex-basis: 100%;
            max-width: 100%;
            margin-right: 0;
        }
    }

    &--12 {
        flex-basis: 100%;
        max-width: 100%;
        margin-right: 0;
    }

    // endregion

    // region Item

    &-item {
        display: flex;
        flex: 1;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: flex-start;
        align-content: flex-start;
        justify-content: flex-start;
        padding: 0 ($spacing * 2) 0 ($spacing * 2);

        @include breakpoint(tablet) {
            flex-basis: 100%;
            width: 100%;
            padding-right: 0;
            padding-left: 0;
        }

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }

        &__icon {
            display: block;
            margin-right: $spacing * 2;

            .svg {
                width: $spacing * 4;
                height: $spacing * 4;
            }
        }

        &__title {
            font: $font-body;
            display: block;
            margin-bottom: $spacing;
        }

        &__content {
            flex: 1;
        }
    }

    &-settings-modal-toggle {
        right: -$spacing;
        .button__icon {
            svg {
                transition: getTransition();
                fill: getColor(black);
            }

            &:hover {
                svg {
                    fill: getColor(red);
                }
            }
        }
    }

    // endregion

    .chartjs-render-monitor {
        width: 100% !important;
    }

    .table__inner {
        .notification {
            margin-left: $spacing;
            margin-right: $spacing;
            width: calc(100% - (#{$spacing} * 2));
        }
    }
}
