@import 'erpcore/assets/scss/main';

.challenge-type-date {
    &__custom-header {
        display: flex;
        flex-direction: column;

        &-inputs {
            display: flex;
            justify-content: space-between;
            margin-bottom: $spacing * 3;

            & > * {
                flex-basis: 45%;
                max-width: 150px;
            }
        }

        &-current {
            margin-bottom: $spacing * 3;
            display: flex;
            justify-content: center;
            align-items: center;

            h4 {
                margin: 0 $spacing * 2;
            }
        }
    }
}
