@import 'erpcore/assets/scss/main';

.main {
    flex: 0 0 auto;
}

.list {
    width: 100%;
    padding: $spacing 0 $spacing * 2 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;

    &__item {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        align-content: flex-start;
        padding-right: $spacing * 4;
        padding-bottom: $spacing * 2;
    }

    &__icon {
        padding-right: $spacing;

        :global {
            .isvg {
                width: $spacing * 4;
                height: $spacing * 4;

                path {
                    fill: getColor(mid-grey);
                }
            }
        }
    }

    &__title {
        display: block;
        font: $font-list;
        color: getColor(mid-grey);
    }

    &__subtitle {
        display: block;
        font: $font-4;
    }
}
