@import 'erpcore/assets/scss/main';

.user-widget {
    width: 100%;
    height: 100%;
    display: flex;

    &__photo {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 98px;
        height: 98px;
        border-radius: 50%;
        overflow: hidden;
        border: 1px solid getColor(light-grey);
        margin-right: $spacing * 2;

        &:hover {
            box-shadow: 0 0 3px 0 rgba(102, 103, 112, 0.22);
        }

        img {
            display: block;
            overflow: hidden;
        }

        .svg {
            display: block;
            width: 100%;
            height: auto;
            fill: getColor(silver-grey);
            overflow: hidden;
        }
    }

    &__title {
        position: relative;
        flex-grow: 1;
        margin: 0 0 ($spacing * 2);
        font: $font_2;
    }

    &__menu {
        display: block;
        margin: 0 0 ($spacing * 3);
        padding: 0;
        list-style-type: none;

        &:last-child {
            margin-bottom: 0;
        }

        &-item {
            width: 100%;
            margin: 0 0 2px;
            padding: 0;

            &-link {
                color: inherit;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__content-title {
        position: relative;
        flex-grow: 1;
        margin: 0 0 $spacing / 2 0;
        font: $font_2;
    }

    .widget__content {
        @include breakpoint(tablet) {
            display: flex;
            flex-wrap: nowrap;
            justify-content: flex-start;
        }
    }
}
