@import 'erpcore/assets/scss/main';

.notification {
    $root: &;
    width: 100%;
    display: flex;
    overflow: hidden;
    text-align: left;
    position: relative;
    border-style: solid;
    border-width: 2px;
    border-color: getColor(sun);
    border-radius: 3px;
    -webkit-appearance: none;
    margin-bottom: $spacing * 2;
    padding: $spacing * 2;
    background-color: transparent;
    opacity: 1;
    transition: all 0.25s ease-in-out;

    &:last-child {
        margin-bottom: 0px;
    }

    &__title {
        font: $font-4;
        margin: 0 0 $spacing 0;
        color: getColor(black);
    }

    &__text {
        font: $font-list;
        margin: 0 0 $spacing * 2 0;
        color: getColor(mid-grey);
    }

    &__icon {
        display: inline-block;
        margin-right: $spacing;
        width: 20px;
        min-width: 20px;
        fill: getColor(sun);
    }

    &__close {
        $_close: &;
        display: block;
        position: absolute;
        top: $spacing;
        right: $spacing;
        width: $spacing * 3;
        height: $spacing * 3;
        padding: $spacing;
        font: $font-list;
        margin: 0;
        border: none;
        background-color: transparent;
        -webkit-appearance: none;
        cursor: pointer;

        &-icon {
            display: block;
            width: $spacing;
            height: $spacing;
            fill: getColor(black);
        }

        &:hover,
        &:active,
        &:focus {
            #{$_close}-icon {
                fill: getColor(red);
            }
        }
    }

    .button {
        position: relative;
        height: 28px;
        font: $font-list;
        color: getColor(black);
        padding: 5px $spacing;
        margin: 0 $spacing 0 0;
        background-color: transparent;

        &:before {
            content: '';
            position: absolute;
            display: block;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            border-radius: 3px;
            z-index: 1;
            transition: getTransition();
            border: 2px solid getColor(silver-light-grey);
        }

        &__icon {
            width: 16px;
            height: 16px;
            vertical-align: middle;
        }

        &__icon,
        &__label {
            margin-right: $spacing/2;
        }

        &:hover {
            &:before {
                border: 2px solid getColor(light-grey);
            }
        }
    }

    &--success {
        border-color: getColor(apple);

        #{$root}__icon {
            fill: getColor(apple);
        }
    }

    &--warning {
        border-color: getColor(sun);

        #{$root}__icon {
            fill: getColor(sun);
        }
    }

    &--error {
        border-color: getColor(tomato);

        #{$root}__icon {
            fill: getColor(tomato);
        }
    }

    &--info {
        border-color: getColor(sky);

        #{$root}__icon {
            fill: getColor(sky);
        }
    }

    &--pro-tip {
        border-color: getColor(light-grey);

        #{$root}__icon {
            fill: getColor(light-grey);
        }
    }

    .floating-notifications & {
        color: getColor(white);
        background-color: getColor(apple);

        &__content {
            width: 100%;
            padding-right: $spacing * 2;
        }

        &__close {
            $_close: &;
            display: block;

            &-icon {
                fill: getColor(white);
            }

            &:hover,
            &:active,
            &:focus {
                #{$_close}-icon {
                    fill: getColor(redlight);
                }
            }
        }

        &__title {
            color: getColor(white);
        }

        &__text {
            color: getColor(white);
        }

        &__icon {
            fill: getColor(white);
        }

        .button {
            color: getColor(white);

            &:before {
                border-color: getColor(white);
            }

            &__icon {
                svg {
                    fill: getColor(white);
                }
            }

            &:hover {
                &:before {
                    border-color: getColor(light-grey);
                }
            }
        }

        &--success {
            background-color: getColor(apple);

            #{$root}__icon {
                fill: getColor(white);
            }
        }

        &--warning {
            background-color: getColor(sun);

            #{$root}__icon {
                fill: getColor(white);
            }
        }

        &--error {
            background-color: getColor(tomato);

            #{$root}__icon {
                fill: getColor(white);
            }
        }

        &--info {
            background-color: getColor(sky);

            #{$root}__icon {
                fill: getColor(white);
            }
        }

        &--transition {
            &-enter {
                opacity: 0.01;
                transform: translateX(-$spacing * 2);

                &-active {
                    opacity: 1;
                    transform: translateX(0);
                    transition: opacity 500ms ease-in, transform 500ms ease-in;
                }
            }

            &-leave {
                opacity: 1;
                transform: translateX(0);

                &-active {
                    opacity: 0.01;
                    transform: translateX(-$spacing * 2);
                    transition: opacity 300ms ease-in, transform 300ms ease-in;
                }
            }
        }
    }
}
