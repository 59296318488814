@import 'erpcore/assets/scss/main';

.event-settings-page-header-actions {
    display: flex;
    margin-right: $spacing;
    align-items: center;
    padding-left: $spacing * 2;
}

.welcome-email-sent-badge {
    display: inline-block;
    position: relative;
    top: 3px;
    margin-right: $spacing;

    &--red {
        fill: getColor(red);
    }

    &--apple {
        fill: getColor(apple);
    }
}