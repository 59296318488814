@import 'erpcore/assets/scss/main';

.table {
    $table: &;
    width: 100%;
    font: $font-body;
    padding-bottom: $spacing * 2;
    color: getColor(black);
    border-top: 1px solid getColor(light-grey);
    border-bottom: 1px solid getColor(light-grey);

    &__inner {
        display: block;
        margin: 0 -1 * $spacing;

        @include breakpoint($to: tablet) {
            margin: 0;
        }
    }

    &__list {
        width: 100%;
        text-align: left;
        position: relative;

        &--is-sorting {
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(getColor(white), 0.4);
                z-index: 100;
                border: 4px dashed getColor('mid-grey');
            }
        }

        &-draggable {
            position: relative;
            z-index: 0;
            background-color: #f3f3f3;
            border: unset;
            border-radius: 3px;
            outline: none;
            overflow: visible !important;

            &-cell {
                padding: $spacing;
                display: flex;
                align-items: center;
                height: 100%;

                & * {
                    white-space: normal;
                }

                &--right {
                    text-align: right;
                }

                &--left {
                    text-align: left;
                }

                &--center {
                    text-align: center;
                }

                &:last-child {
                    text-align: right;
                    justify-content: flex-end;
                }
            }

            &-row {
                background-color: getColor(white);
                border: none !important;

                &:hover {
                    &:hover {
                        #{$table}-actions__items {
                            opacity: 1;
                            visibility: visible;
                            pointer-events: all;
                            padding-right: $spacing;
                        }

                        #{$table}-actions__dots {
                            opacity: 0;
                            visibility: hidden;
                        }
                    }
                }
            }
        }
    }

    &__header {
        font: $font-4;

        &-cell {
            padding: ($spacing * 3) $spacing;
            text-transform: none;

            &:last-child {
                text-align: right;
            }

            &--center {
                text-align: center;
            }

            &--right {
                text-align: right;
            }
        }
    }

    &__body {
        tr {
            box-shadow: 0 0 0 0 getColor(silver-grey);
            transition: box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out;

            &:hover {
                background-color: getColor(white);
                box-shadow: 0 0 4px -2px getColor(silver-grey);

                #{$table}-actions__items {
                    opacity: 1;
                    visibility: visible;
                    pointer-events: all;
                    padding-right: $spacing;
                }

                #{$table}-actions__dots {
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }

        td {
            padding: $spacing;
        }

        &-data {
            &--center {
                text-align: center;
            }

            &--right {
                text-align: right;
            }

            &--smallicon {
                width: $spacing * 2 + 25px;
            }

            &--drag {
                display: inline-flex;
                align-items: center;
                cursor: grab;
                position: relative;
                z-index: 10000;

                &-icon {
                    display: inline-block;
                    float: left;
                    margin-right: $spacing * 1.5;
                }
            }
        }
    }

    &__truncate {
        display: block;
        max-width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__sortable {
        position: relative;
        display: flex;
        width: 100%;
        border: none;
        font: $font-4;
        text-align: left;
        padding-left: 0;
        padding-right: 0;
        cursor: pointer;
        white-space: nowrap;

        background: transparent;
        transition: getTransition();
        color: getColor(black);
        margin-bottom: $spacing;

        &:last-child {
            margin-bottom: 0;
        }

        &:hover {
            color: getColor(red);

            .table__sortable-icon {
                opacity: 1;
                animation: bounce 1s infinite;

                svg {
                    fill: getColor(red);
                }
            }
        }
    }

    &__sortable-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: $spacing;
        height: 18px;
        margin-left: $spacing;
        transform: rotate(180deg);
        transition: getTransition();
        color: getColor(black);
        opacity: 0;

        svg {
            display: block;
            width: $spacing;
            height: $spacing;
            transition: getTransition();
            fill: getColor(black);
        }
    }

    &__sortable--asc {
        .table__sortable-icon {
            opacity: 1;
            transform: rotate(180deg);
            animation: none;
        }

        &:hover {
            .table__sortable-icon {
                animation: none;
            }
        }
    }

    &__sortable--desc {
        .table__sortable-icon {
            opacity: 1;
            transform: rotate(0deg);
            animation: none;
        }

        &:hover {
            .table__sortable-icon {
                animation: none;
            }
        }
    }
}

.table-mobile {
    $table: &;
    display: block;

    &__additional {
        display: none;
        padding-top: $spacing * 2;
    }

    &__btn {
        left: $spacing * -3;
        border: 0;
        z-index: 2;
        padding: 0;
        font-size: 0;
        width: calc(100% + #{$spacing * 6});
        line-height: 0;
        display: block;
        cursor: pointer;
        position: absolute;
        top: -2 * $spacing;
        bottom: -2 * $spacing;
        -webkit-appearance: none;
        margin: 0;
        background-color: transparent;
    }

    &__list-drag-icon {
        margin-right: $spacing * 3;
        position: relative;
        z-index: 1000;
        cursor: grab;
    }

    &__list {
        display: block;
        margin-left: -3 * $spacing;
        margin-right: -3 * $spacing;
    }

    &__list-item {
        padding: $spacing * 2 $spacing * 3;
        border-top: 1px solid getColor(silver-light-grey);

        &:last-child {
            border-bottom: 1px solid getColor(silver-light-grey);
        }
    }

    &__list-header {
        margin: 0;
        padding: 0;
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        position: relative;
        flex-direction: row;
        align-items: center;
        list-style-type: none;
        align-content: center;
        justify-content: flex-end;
    }

    &__list-checkbox {
        z-index: 3;
        position: relative;
        margin-right: $spacing * 2;

        .input-checkbox,
        .input-checkbox__icon {
            margin: 0;
        }
    }

    &__list-title {
        font: $font-4;
        display: block;
        margin-right: auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__list-status {
        display: block;
        padding-left: $spacing * 2;
    }

    &__list-arrow {
        display: block;
        width: $spacing * 4;
        padding-left: $spacing * 2;

        .svg,
        svg {
            display: block;
            width: $spacing * 2;
            height: $spacing * 2;
            fill: getColor(mid-grey);
        }
    }

    &__list-subtitle {
        margin: 0;
        width: 100%;
        font: $font-body;
        list-style-type: none;
        padding: $spacing * 2.5 0 0 0;
    }

    &__list-subtitle-item {
        display: block;
        padding-bottom: $spacing/2;

        &:last-child {
            padding-bottom: 0;
        }
    }

    &__table {
        width: 100%;
        display: table;
        font: $font-list;
        table-layout: fixed;
        word-break: break-word;
    }

    &__table-row {
        td {
            .status-badge:first-child {
                margin-left: 0;
            }
        }
    }

    &__table-col-left {
        font: $font-5;
        width: 120px;
        vertical-align: top;
        padding: $spacing/2 $spacing * 2 0 0;
    }

    &__table-col-right {
        vertical-align: top;
        padding: $spacing/2 0 0 0;
    }

    &__actions {
        display: block;
        padding-top: $spacing * 2;

        .table-actions {
            float: none;
            width: auto;
            height: auto;
            cursor: default;

            &__dots {
                display: none;
            }

            &__items {
                top: auto;
                margin: 0;
                opacity: 1;
                padding: 0;
                right: auto;
                width: 100%;
                bottom: auto;
                display: flex;
                position: relative;
                visibility: visible;
                flex-direction: row;
                pointer-events: all;
                justify-content: flex-start;
            }

            &__item {
                padding-left: 0;
                padding-right: $spacing;

                &:empty {
                    display: none;
                }

                &:last-child {
                    padding-right: 0;
                }

                &:last-child:first-child {
                    padding-right: $spacing;
                }

                a,
                button {
                    width: auto;
                    height: auto;
                    cursor: pointer;
                    padding: $spacing;
                    border-radius: 3px;
                    -webkit-appearance: none;
                    border: 1px solid getColor(silver-grey);
                }

                svg,
                .svg {
                    display: block;
                }
            }
        }
    }

    &__sorting {
        display: inline-block;
        position: relative;
        margin-bottom: $spacing * 2;
    }

    &__bulk {
        display: inline-block;
        margin-right: $spacing * 2;
    }

    &__sorting-btn {
        margin: 0;
        padding: 0;
        width: auto;
        border: none;
        display: flex;
        cursor: pointer;
        border-radius: 0;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;
        align-content: center;
        -webkit-appearance: none;
        justify-content: flex-start;
        background-color: transparent;
    }

    &__sorting-dropdown {
        top: 100%;
        z-index: 10;
        width: 200px;
        display: none;
        border-radius: 3px;
        position: absolute;
        left: -2 * $spacing;
        margin-top: $spacing * 2;
        background-color: getColor(white);
        padding: $spacing * 2 $spacing * 3;
        box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);

        @include breakpoint(small) {
            width: 70vw;
            left: -3 * $spacing;
        }

        &:before {
            top: -5px;
            left: 17px;
            z-index: 1;
            width: 14px;
            height: 14px;
            content: '\A';
            position: absolute;
            pointer-events: none;
            transform: rotate(45deg);
            background-color: getColor(white);
            box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);

            @include breakpoint(small) {
                left: 27px;
            }
        }

        &:after {
            left: 0;
            top: 2px;
            right: 2px;
            z-index: 2;
            content: '\A';
            position: absolute;
            pointer-events: none;
            height: $spacing * 2;
            background-color: getColor(white);
        }
    }

    &__sorting-icon {
        width: 16px;
        display: block;
        margin-right: $spacing;

        svg {
            width: 100%;
            display: block;
            fill: getColor(silver-grey);
        }
    }

    &__sorting-label {
        font: $font-body;
        display: block;

        strong {
            color: getColor(dark-brown);
        }
    }

    &__sorting-item {
        display: block;
        padding: $spacing/2 0;
    }

    // region Modifiers

    &__list-item--active {
        #{$table}__additional {
            display: block;
        }
        #{$table}__list-arrow svg {
            transform: rotate(90deg);
        }
    }

    &__sorting--active {
        #{$table}__sorting-dropdown {
            display: block;
        }
    }

    &__sorting--ASC {
        #{$table}__sorting-icon svg {
            path:first-child {
                fill: getColor(red);
            }
        }
    }
    &__sorting--DESC {
        #{$table}__sorting-icon svg {
            path:last-child {
                fill: getColor(red);
            }
        }
    }

    // endregion
}

.table-grid {
    width: 100%;
    display: block;
    padding: $spacing * 2 $spacing 0 $spacing;

    &__row {
        display: block;
        margin-left: -1 * $spacing;
        margin-right: -1 * $spacing;
    }

    &__row-flex {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: stretch;
        align-content: stretch;
        justify-content: flex-start;
    }

    &__col {
        width: 16.66%;
        padding: $spacing;

        .sidebar--show-deck + .main & {
            @media (max-width: 1300px) {
                width: 20%
            }

            @media (max-width: 1130px) {
                 width: 25%
            }
        }
    }
}

@keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        margin-top: 0;
    }
    40% {
        margin-top: -4px;
    }
    60% {
        margin-top: -2px;
    }
}
