@import 'erpcore/assets/scss/main';

$maxHeight: '550px';

.search {
    display: block;
    margin-bottom: $spacing;
    padding-bottom: $spacing;
    border-bottom: 1px solid getColor(light-grey);
}

.gallery {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: flex-start;
    align-items: stretch;
    justify-content: flex-start;
    margin-bottom: $spacing;
    border-bottom: 1px solid getColor(light-grey);

    &__left {
        display: block;
        flex: 1 1 auto;
    }

    &__right {
        display: block;
        flex: 0 0 $spacing * 36;
        margin-bottom: $spacing;
        margin-left: $spacing * 2;
        border-left: 1px solid getColor(light-grey);

        @include breakpoint(tablet) {
            display: none;
        }
    }

    &__row {
        display: block;
        margin-left: -1 * $spacing / 2;
        margin-right: -1 * $spacing / 2;
    }

    &__listing {
        display: block;
        flex: 1 1 auto;
        overflow: auto;
        max-height: #{$maxHeight};

        @include breakpoint(tablet) {
            max-height: 100%;
        }
    }

    &__infinite {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        align-content: flex-start;
    }

    &__loader {
        width: 100%;
        flex: 1 1 100%;
        display: block;
        padding-bottom: $spacing;
    }

    &__col {
        width: 16.6%;
        padding-bottom: $spacing;
        padding-left: $spacing / 2;
        padding-right: $spacing / 2;

        @include breakpoint(tablet) {
            width: 33.33%;
        }

        @include breakpoint(small) {
            width: 50%;
        }

        &--is-4 {
            width: 25%;

            @include breakpoint(tablet) {
                width: 33.33%;
            }

            @include breakpoint(small) {
                width: 50%;
            }
        }
    }
}

.item {
    height: 0;
    margin: 0;
    width: 100%;
    border: none;
    display: block;
    cursor: default;
    font: $font-list;
    border-radius: 0;
    text-align: left;
    position: relative;
    padding-bottom: 100%;
    background-size: cover;
    color: getColor(white);
    -webkit-appearance: none;
    background-position: center;
    background-repeat: no-repeat;
    background-color: getColor(mid-grey);

    @include fileTypesBackground;

    &__title {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 5;
        position: absolute;
        word-break: break-word;
        padding: $spacing * 1.5;

        span {
            width: 100%;
            height: 100%;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
    }

    &[type='button'] {
        cursor: pointer;

        &:after {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            z-index: 1;
            content: '\A';
            visibility: hidden;
            position: absolute;
            pointer-events: none;
            transition: getTransition();
            background-color: rgba(getColor(black), 0.6);
        }
    }

    &:before {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        opacity: 0;
        content: '\A';
        position: absolute;
        visibility: hidden;
        pointer-events: none;
        transition: getTransition();
        border: $spacing / 2 solid getColor(red-hover);
    }

    &:hover:after {
        opacity: 1;
        visibility: visible;
    }

    &__active {
        &:before,
        &:after,
        &[type='button']:after {
            opacity: 1;
            visibility: visible;
        }
    }

    &:disabled {
        cursor: not-allowed;

        &:hover:after {
            opacity: 0;
            visibility: hidden;
        }
    }
}

.is-image {
    background-color: getColor(white);
    border: 1px solid getColor(light-grey);
}

.checkmark {
    z-index: 10;
    width: $spacing * 2.5;
    height: $spacing * 2.5;
    position: absolute;
    right: $spacing * 1.5;
    bottom: $spacing * 1.5;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    border-radius: 100%;
    background-color: getColor(red-hover);
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    transition: getTransition();

    &__active {
        opacity: 1;
        visibility: visible;
    }

    svg {
        width: 50%;
        display: block;

        path {
            fill: getColor(white);
        }
    }
}

.sidebar {
    display: block;
    overflow: auto;
    padding: $spacing * 2;
    max-height: #{$maxHeight};

    &__title {
        padding: 0;
        width: 100%;
        font: $font-2;
        display: block;
        margin: 0 0 $spacing * 2 0;
    }

    &__list {
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        list-style-type: none;
        align-items: flex-start;
        align-content: flex-start;
        justify-content: flex-start;

        li {
            width: 33.33%;
            display: block;
            padding-right: $spacing;
            padding-bottom: $spacing;
        }
    }

    &__details {
        padding: 0;
        width: 100%;
        display: block;
        font: $font-filters;
        list-style-type: none;
        margin: $spacing 0 0 0;
        color: getColor(mid-grey);

        li {
            margin: 0;
            display: block;
            word-break: break-word;
            padding: 0 0 $spacing / 2 0;
        }
    }

    &__actions {
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        margin: $spacing 0 0 0;

        li {
            padding-top: $spacing;
            list-style-type: none;
            padding-right: $spacing;
        }
    }
}
