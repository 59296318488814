@import 'erpcore/assets/scss/main';

.entity__content {
    .repeater__item {
        padding-right: $spacing * 10 !important;

        &-remove {
            right: 38px;
        }
    }

    .collapse__toggle {
        right: -75px;
        top: 3px;
    }
}
