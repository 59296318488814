@import 'erpcore/assets/scss/main';

.event-view-actions {
    padding: $spacing * 4;
    background-color: getColor('light-grey');
    margin-bottom: $spacing * 3;
    height: 100%;

    & button,
    & a {
        display: block;
        margin-bottom: $spacing * 4;
        font-weight: 300;
        text-align: left;

        @include breakpoint($to: desktop) {
            margin-bottom: $spacing * 1.9;
        }

        .svg svg {
            fill: getColor('mid-grey');
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__qr-code {
        width: 320px;
        max-width: 100%;
        height: auto;
    }

    &--flex {
        display: flex;

        @media (max-width: 1900px) {
            flex-wrap: wrap;
        }
    }

    &__col {
        flex: 1 1 100%;

        @media (max-width: 1900px) {
            flex: 0 0 100%;
        }

        & + & {
            flex: 0 0 50%;
            padding-left: $spacing;

            @media (max-width: 1900px) {
                flex: 0 0 100%;
                padding-left: 0;
                padding-top: 30px;
            }
        }
    }
}
