@import 'erpcore/assets/scss/main';

.star-rating {
    display: flex;

    &__rating {
        display: inline-block;
        position: relative;
        top: 2px;

        &--left {
            margin-right: $spacing * 2;
        }

        &--right {
            margin-left: $spacing;
        }
    }

    &__single {
        margin-right: $spacing / 2;
        width: 18px;
        height: 18px;
        position: relative;

        &-outer {
            position: absolute;
            height: 100%;
            width: 100%;
        }

        &-inner {
            position: absolute;
            width: 0%;
            height: 18px !important;
            overflow: hidden;
        }

        &:last-child {
            margin-right: 0;
        }

        .svg {
            width: 18px;
            height: 18px;
            fill: getColor('mid-grey');
        }
    }
}
